<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      agents: [],
      // assets expensis
      currentAssetsExpensis: [],
      paymentTypes: [
        { id: 1, name: "مديونية", name_en: "Pay On Credit" },
        { id: 2, name: "دفع جزئي", name_en: "Parital Pay" },
        { id: 3, name: "دفع كامل", name_en: "Full Pay" },
      ],
      // end asets expensis
      creditMainChartOfAccountsId: 3,
      debitMainChartOfAccountsId: 2,
      purchases: [],
      showedEditedPurchase: {},
      agent: {},
      editedAgent: {},
      levelOne: [],
      levelTwo: [],
      currentAssets: [],
      currentExpensis: [],
      addAsset: [{}],
      addExpense: [{}],
      addAccounts: [{}],
      seletedPurchase: {},
      seletedEditedPurchase: {},
      addPurchase: [
        {
          addAsset: [{}],
          addExpense: [{}],
          purchaseType: "",
        },
      ],
      editPurchase: [
        {
          addAsset: [{}],
          addExpense: [{}],
          purchaseType: "",
        },
      ],
      editAgent: {},
      isOnCredit: true,
      page: 1,
      limit: 20,
      tot_pages: 0,
      branch_id: null,
    };
  },
  methods: {
    deleteOrder(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("purchases", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    completePurchase() {
      console.log({ accounts: this.addAccounts, order: this.seletedPurchase });
      this.http
        .post("purchases/complete", {
          order: this.seletedPurchase,
          accounts: this.addAccounts,
          purchaseType: this.selectedPaymentType,
        })
        .then((res) => {
          if (res.status) {
            this.addAccounts = [{}];
            this.selectedPaymentType = 1;
            this.seletedPurchase.excution_time = res.data[0].excution_time;
            this.get(this.page);
          }
        });
    },
    deleteOption(index) {
      this.addAccounts.splice(index, 1);
    },
    deleteEditOption(index) {
      this.editPurchase[0].addAsset.splice(index, 1);
    },
    startPaymentProcess() {
      if (this.selectedPaymentType == 1) {
        this.isOnCredit = true;
      } else {
        this.isOnCredit = false;
      }
    },
    // assets expensis
    getAssets() {
      this.http.get("purchases/get-assets").then((resp) => {
        this.currentAssetsExpensis = resp.data;
      });
    },
    getAgents() {
      this.http.get("purchases/agents").then((res) => {
        this.agents = res.data;
      });
    },
    // end assets expensis
    purchase() {
      console.log({ agent: this.agent, expenses: this.addPurchase });
      this.http
        .post("purchases/purchase", {
          expensis: this.addPurchase,
          agent: this.agent,
        })
        .then((resp) => {
          if (resp) {
            this.addPurchase = [
              {
                addAsset: [{}],
                addExpense: [{}],
                addAccounts: [{}],
                agent: {},
              },
            ];
            this.agent = {};
            this.get(this.page);
          }
        });
    },
    editPurchases() {
      this.http
        .put("purchases", this.seletedEditedPurchase?.id, {
          purchase: this.editPurchase,
          agent: this.editAgent,
        })
        .then((res) => {
          if (res.status) {
            this.get(this.page);
          }
        });
    },
    showPurchase(purchase) {
      this.showedEditedPurchase = purchase;
    },
    startEditPurchase(purchase) {
      console.log(purchase);
      this.editAgent = purchase.agent;
      this.seletedEditedPurchase = purchase;
      this.editPurchase[0].addAsset =
        this.seletedEditedPurchase.purchase_order_details;
    },
    getCashAccounts() {
      this.http.post("transactions/cash-accounts", {}).then((res) => {
        this.currentAssets = res.data;
      });
    },
    getPurchaseData() {
      this.currentAssets = [];
      this.http
        .post("purchases/purchases-data", {
          branch_id: this.branch_id,
        })
        .then((resp) => {
          // this.currentExpensis = resp.dataItems.filter((item) => {
          //   return item;
          // });
          this.currentAssets = resp.data.filter((item) => {
            return item;
          });
          // console.log({
          //   assets: this.currentAssets,
          //   expensis: this.currentExpensis,
          // });
        });
    },
    bindPurchase(app) {
      this.seletedPurchase = app;
    },
    getPurchases() {
      this.http.get("purchases").then((res) => {
        this.purchases = res.data;
      });
    },
    getLevelOne() {
      this.http
        .post("level-one-chart-of-accounts", {
          main_chart_of_accounts_id: this.creditMainChartOfAccountsId,
        })
        .then((res) => {
          this.levelOne = res.data;
        });
    },
    getLevelTwo() {
      this.http
        .post("level-two-chart-of-accounts", {
          main_chart_of_accounts_id: this.debitMainChartOfAccountsId,
        })
        .then((res) => {
          this.levelTwo = res.data;
        });
    },
    get(page) {
      console.log(page);
      this.http
        .post("purchases/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.purchases = res.data;
          this.purchases.forEach((purchase) => {
            purchase.total = 0;
            purchase?.purchase_order_details.forEach((detail) => {
              purchase.total +=
                parseFloat(detail.unit_price) * parseFloat(detail.qty);
            });
          });
        });
    },
    formatedTimestamp(unformatedDate) {
      const d = new Date(unformatedDate);
      const time = d.toTimeString().split(" ")[0];
      return `${time.split(":")[0]}:${time.split(":")[1]}`;
    },
    formatedDatestamp(unformatedDate) {
      const d = new Date(unformatedDate);
      const date = d.toISOString().split("T")[0];
      return `${date}`;
    },
  },
  created() {
    this.get(1);
    this.getAgents();
    this.getAssets();
    this.getPurchaseData();
    this.getCashAccounts();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.payments.sub.invoices')"
    />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <router-link to="/new-payment">
            <button type="button" class="btn btn-light float-end mb-4">
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </router-link>

          <table
            class="table table-centered table-nowrap table-striped table-hover align-middle"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">#</th>
                <th scope="col">{{ $t("purchases.code") }}</th>
                <th scope="col">{{ $t("purchases.supplier") }}</th>
                <th scope="col">{{ $t("purchases.total") }}</th>
                <th scope="col">{{ $t("purchases.created") }}</th>
                <th scope="col">{{ $t("purchases.updated") }}</th>
                <th scope="col">{{ $t("purchases.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(app, index) in purchases" :key="app" class="">
                <td>{{ index + 1 }}</td>
                <td>{{ "#" + app?.id }}</td>
                <td>
                  {{
                    $i18n.locale == "ar"
                      ? app?.agent?.name
                      : app?.agent?.name_en
                  }}
                </td>
                <td>{{ app?.total.toLocaleString() }}</td>
                <td>{{ app.created?.split("T")[0] }}</td>
                <td>{{ app.updated?.split("T")[0] }}</td>
                <td>
                  <button
                    v-if="!app.excution_time"
                    type="button"
                    style="margin-inline-end: 4px"
                    class="btn btn-primary btn-sm"
                    data-bs-target="#completeModal"
                    data-bs-toggle="modal"
                    @click="bindPurchase(app)"
                  >
                    {{ $t("purchases.complete_order") }}
                  </button>
                  <button v-if="app.excution_time" class="btn">
                    <i
                      v-if="app.excution_time"
                      style="color: green"
                      class="bx bx-check fa-2x"
                    ></i>
                  </button>
                  <button
                    data-bs-target="#seeModal"
                    data-bs-toggle="modal"
                    @click="showPurchase(app)"
                    v-if="app.excution_time"
                    class="btn"
                  >
                    <i
                      v-if="app.excution_time"
                      class="text-primary bx bx-show fa-2x"
                    ></i>
                  </button>
                  <!-- <button
                    v-if="!app.excution_time"
                    type="button"
                    style="margin-inline-end: 4px"
                    class="btn btn-primary btn-sm"
                    data-bs-target="#editModal"
                    data-bs-toggle="modal"
                    @click="startEditPurchase(app)"
                  >
                    {{ $t("popups.edit") }}
                  </button> -->
                  <router-link
                    style="margin-inline-end: 4px"
                    v-if="!app.excution_time"
                    :to="'/edit-payment/' + app?.id"
                  >
                    <button type="button" class="btn btn-primary btn-sm">
                      {{ $t("popups.edit") }}
                    </button>
                  </router-link>
                  <button
                    v-if="!app.excution_time"
                    type="button"
                    style="margin-inline-end: 4px"
                    class="btn btn-danger btn-sm"
                    @click="deleteOrder(app)"
                  >
                    {{ $t("popups.delete") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- end  table -->

    <!--Start Add Modal-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form @submit.prevent="purchase()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("payments.create_order") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-6"></div>
                <div
                  class="col-6"
                  style="display: flex; justify-content: flex-end"
                >
                  <!-- <button
                    type="button"
                    @click="
                      addPurchase.push({
                        addAsset: [{}],
                        addExpense: [{}],
                        addAccounts: [{}],
                        purchaseType: '',
                      })
                    "
                    class="btn float-end"
                  >
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button> -->
                </div>
              </div>
              <div class="row purchase-container">
                <div class="col">
                  <label for="asset_id">{{ $t("purchases.supplier") }}</label>
                  <select v-model="agent" class="form-select mb-2">
                    <option v-for="agent in agents" :key="agent" :value="agent">
                      {{ $i18n.locale == "ar" ? agent?.name : agent?.name_en }}
                    </option>
                  </select>
                </div>
                <div class="col"></div>
              </div>
              <div v-for="purchase in addPurchase" :key="purchase">
                <div class="purchase-container">
                  <div class="row mb-2">
                    <div class="col-10">
                      <label for="asset_id">{{
                        $t("purchases.chose_items")
                      }}</label>
                    </div>
                    <div class="col-2">
                      <button
                        type="button"
                        @click="addPurchase[0].addAsset.push({})"
                        class="btn"
                      >
                        <span
                          class="bx bxs-plus-circle fa-2x"
                          style="color: #2a3042 !important"
                        ></span>
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      v-for="asset in purchase.addAsset"
                      :key="asset"
                      class="d-flex justify-content-between flex-column"
                    >
                      <div class="d-flex justify-content-between">
                        <select
                          v-model="asset.expense_id"
                          class="form-select mb-2"
                          id="asset_id"
                          :placeholder="$t('emps.account')"
                        >
                          <option value="" disabled selected>
                            {{ $t("popups.chose_item") }}
                          </option>
                          <option
                            v-for="expenses in currentExpensis"
                            :key="expenses"
                            :value="expenses?.id"
                          >
                            {{
                              $i18n.locale == "ar"
                                ? expenses?.name
                                : expenses?.name_en
                            }}
                          </option>
                        </select>
                        <input
                          type="number"
                          v-model="asset.qty"
                          class="form-control mb-2 mx-1"
                          :placeholder="$t('emps.qty')"
                        />
                        <input
                          type="number"
                          v-model="asset.unit_price"
                          class="form-control mb-2 mx-1"
                          :placeholder="$t('emps.unit_price')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button
                data-bs-dismiss="modal"
                v-if="seletedPurchase.excution_time == null"
                type="submit"
                class="btn btn-primary"
              >
                {{ $t("payments.order") }}
              </button>
              <button
                data-bs-dismiss="modal"
                v-if="seletedPurchase.excution_time != null"
                disabled
                class="btn btn-primary"
              >
                {{ $t("payments.completed") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Add Modal-->

    <!--Start Edit Modal-->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form @submit.prevent="editPurchases()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("payments.edit_order") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-6"></div>
                <div
                  class="col-6"
                  style="display: flex; justify-content: flex-end"
                ></div>
              </div>
              <div class="row purchase-container">
                <div class="col">
                  <label for="asset_id">{{ $t("purchases.supplier") }}</label>
                  <select v-model="editAgent" class="form-select mb-2">
                    <option
                      :selected="editAgent?.id == agent?.id"
                      v-for="agent in agents"
                      :key="agent"
                      :value="agent"
                    >
                      {{ $i18n.locale == "ar" ? agent?.name : agent?.name_en }}
                    </option>
                  </select>
                </div>
                <div class="col"></div>
              </div>
              <div v-for="purchase in editPurchase" :key="purchase">
                <div class="purchase-container">
                  <div class="row">
                    <div class="row d-flex align-items-center">
                      <div class="col">
                        <label for="asset_id">{{
                          $t("purchases.chose_items")
                        }}</label>
                      </div>
                      <button
                        type="button"
                        @click="purchase.addAsset.push({})"
                        class="btn"
                      >
                        <span
                          class="bx bxs-plus-circle float-end fa-2x"
                          style="color: #2a3042 !important"
                        ></span>
                      </button>
                    </div>
                    <div
                      v-for="(asset, index) in purchase.addAsset"
                      :key="asset"
                      class="d-flex justify-content-between flex-column mt-2"
                    >
                      <div class="d-flex justify-content-between">
                        <select
                          v-model="asset.level_three_chart_of_account_id"
                          class="form-select mb-2"
                          id="asset_id"
                          :placeholder="$t('emps.account')"
                        >
                          <option
                            :selected="
                              expenses?.id ==
                              asset?.level_three_chart_of_account_id
                            "
                            v-for="expenses in currentExpensis"
                            :key="expenses"
                            :value="expenses?.id"
                          >
                            {{
                              $i18n.locale == "ar"
                                ? expenses?.name
                                : expenses?.name_en
                            }}
                          </option>
                        </select>
                        <input
                          type="number"
                          v-model="asset.qty"
                          class="form-control mb-2 mx-1"
                          :placeholder="$t('emps.qty')"
                        />
                        <input
                          type="number"
                          v-model="asset.unit_price"
                          class="form-control mb-2 mx-1"
                          :placeholder="$t('emps.unit_price')"
                        />
                        <button
                          :disabled="purchase.addAsset.length == 1"
                          class="btn btn-danger"
                          @click="deleteEditOption(index)"
                        >
                          <i class="bx bx-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button data-bs-dismiss="modal" class="btn btn-primary">
                {{ $t("popups.edit") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Edit Modal-->

    <!--Start Complete Modal-->
    <div
      class="modal fade"
      id="completeModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form @submit.prevent="completePurchase()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("purchases.complete_order") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-6"></div>
                <div
                  class="col-6"
                  style="display: flex; justify-content: flex-end"
                >
                  <!-- <button
                    type="button"
                    @click="
                      addPurchase.push({
                        addAsset: [{}],
                        addExpense: [{}],
                        addAccounts: [{}],
                        purchaseType: '',
                      })
                    "
                    class="btn float-end"
                  >
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button> -->
                </div>
              </div>
              <div class="row purchase-container">
                <div class="col">
                  <h6 for="asset_id">{{ $t("purchases.supplier") }}</h6>
                  <h5>
                    {{
                      $i18n.locale == "ar"
                        ? seletedPurchase?.agent?.name
                        : seletedPurchase?.agent?.name_en
                    }}
                  </h5>
                </div>
                <div class="col"></div>
              </div>
              <div class="purchase-container">
                <div class="row">
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <h6 for="asset_id">{{ $t("purchases.items.text") }}</h6>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>{{ $t("purchases.items.name") }}</th>
                          <th>{{ $t("purchases.items.unit_price") }}</th>
                          <th>{{ $t("purchases.items.qty") }}</th>
                          <th>{{ $t("purchases.items.sub_total") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in seletedPurchase.purchase_order_details"
                          :key="item"
                        >
                          <td>
                            {{
                              $i18n.locale == "ar"
                                ? item?.level_three_chart_of_account?.name
                                : item?.level_three_chart_of_account?.name_en
                            }}
                          </td>
                          <td>
                            {{ parseFloat(item.unit_price)?.toLocaleString() }}
                          </td>
                          <td>{{ parseInt(item.qty)?.toLocaleString() }}</td>
                          <td>
                            {{
                              (
                                parseInt(item.qty) * parseFloat(item.unit_price)
                              ).toLocaleString()
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <th>{{ $t("payments.items.total") }}</th>
                          <th class="total-style">
                            {{
                              parseFloat(
                                seletedPurchase?.total
                              ).toLocaleString()
                            }}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row purchase-container">
                <div class="col">
                  <label for="asset_id" class="mt-2">{{
                    $t("purchases.payment_type")
                  }}</label>
                  <select
                    class="form-select mb-2"
                    v-model="selectedPaymentType"
                    @change="startPaymentProcess()"
                    required
                    id=""
                  >
                    <option
                      v-for="paymentType in paymentTypes"
                      :key="paymentType"
                      :value="paymentType.id"
                    >
                      {{
                        $i18n.locale == "ar"
                          ? paymentType.name
                          : paymentType.name_en
                      }}
                    </option>
                  </select>
                </div>
                <div class="col"></div>
              </div>
              <div v-if="!isOnCredit" class="row purchase-container">
                <div class="col d-flex justify-content-end">
                  <button
                    v-if="addAccounts.length < 3"
                    type="button"
                    @click="addAccounts.push({})"
                    class="btn float-end"
                  >
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                </div>
                <div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("purchases.chose_acccount")
                      }}</label>
                    </div>
                  </div>

                  <div
                    v-for="(account, index) in addAccounts"
                    :key="account"
                    class="d-flex flex-row justify-content-between"
                  >
                    <div class="row my-3">
                      <div class="d-flex justify-content-between">
                        <select
                          v-model="account.asset_id"
                          class="form-select mb-2"
                          id="account_id"
                          :placeholder="$t('emps.account')"
                        >
                          <option value="" disabled selected>
                            {{ $t("popups.chose_account") }}
                          </option>
                          <option
                            v-for="assets in currentAssets"
                            :key="assets"
                            :value="assets?.id"
                          >
                            {{
                              $i18n.locale == "ar"
                                ? assets?.name
                                : assets?.name_en
                            }}
                          </option>
                        </select>
                        <input
                          v-model="account.value"
                          type="number"
                          class="form-control mb-2 mx-1"
                          :placeholder="$t('emps.amount')"
                        />
                        <span class="mx-2">
                          <button
                            class="btn btn-danger"
                            @click="deleteOption(index)"
                            v-if="index > 0"
                          >
                            <i class="bx bx-trash"></i>
                          </button>
                          <button v-else disabled class="btn btn-danger">
                            <i class="bx bx-trash"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button
                data-bs-dismiss="modal"
                type="submit"
                class="btn btn-primary"
              >
                {{ $t("payments.complete") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Complete Modal-->

    <!--Start See Modal-->
    <div
      class="modal fade"
      id="seeModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("purchases.order_details") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-6"></div>
                <div
                  class="col-6"
                  style="display: flex; justify-content: flex-end"
                >
                  <!-- <button
                    type="button"
                    @click="
                      addPurchase.push({
                        addAsset: [{}],
                        addExpense: [{}],
                        addAccounts: [{}],
                        purchaseType: '',
                      })
                    "
                    class="btn float-end"
                  >
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button> -->
                </div>
              </div>
              <div class="row purchase-container">
                <div class="col">
                  <h6 for="asset_id">{{ $t("purchases.supplier") }}</h6>
                  <h5>
                    {{
                      $i18n.locale == "ar"
                        ? showedEditedPurchase?.agent?.name
                        : showedEditedPurchase?.agent?.name_en
                    }}
                  </h5>
                </div>
                <div class="col"></div>
              </div>
              <div class="purchase-container">
                <div class="row">
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <h6 for="asset_id">{{ $t("purchases.items.text") }}</h6>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>{{ $t("purchases.items.name") }}</th>
                          <th>{{ $t("purchases.items.unit_price") }}</th>
                          <th>{{ $t("purchases.items.qty") }}</th>
                          <th>{{ $t("purchases.items.sub_total") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in showedEditedPurchase.purchase_order_details"
                          :key="item"
                        >
                          <td>
                            {{
                              $i18n.locale == "ar"
                                ? item?.level_three_chart_of_account?.name
                                : item?.level_three_chart_of_account?.name_en
                            }}
                          </td>
                          <td>
                            {{ parseFloat(item.unit_price)?.toLocaleString() }}
                          </td>
                          <td>{{ parseInt(item.qty)?.toLocaleString() }}</td>
                          <td>
                            {{
                              (
                                parseInt(item.qty) * parseFloat(item.unit_price)
                              ).toLocaleString()
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <th>{{ $t("payments.items.total") }}</th>
                          <th class="total-style">
                            {{
                              parseFloat(
                                showedEditedPurchase?.total
                              ).toLocaleString()
                            }}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button class="btn btn-primary" disabled>
                {{ $t("payments.completed") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End See Modal-->

    <!--   Purchases  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->
  </Layout>
</template>

<style scoped>
.purchase-container {
  background: #fafafa;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
